import React from 'react';
import { string } from 'prop-types';
import loadable from '@loadable/component';

import {
  REWARD, SHARE, APPLE, ANDROID,
} from 'constants/font-awesome';
import {
  LOYALTY_ROUTE, LOYALTY_SHARE_ROUTE, LOYALTY_SHARE_IOS_ROUTE, LOYALTY_SHARE_ANDROID_ROUTE,
} from 'constants/navigation';

import useStyles from '../styles';

const LazyBanner = loadable(() => import('components/banners/in-app-banners/one'));
const Page = loadable(() => import('components/page'));
const LazyShareView = loadable(() => import('views/loyalty-card/share'));
const LazyOverrideFooter = loadable(() => import('components/footer/override-footer'));

const Typography = loadable(() => import('@material-ui/core/Typography'));

const SuspendedSharePage = ({ passId = '', title = '' }) => {
  const styles = useStyles();

  return (
      <Page title="Share card" description={`Share your loyalty card: "${title}"`}>
        <LazyBanner/>
        <Typography variant="h2" component="h1" className={styles.heading} align="center">
          {'Share Loyalty Card'}
        </Typography>
        <Typography variant="h4" component="p" className={styles.headingMessage} align="center">
         {'Select the customers device type to continue.'}
        </Typography>
        <div className={styles.content}>
          <LazyShareView passId={passId} title={title} />
        </div>
        <LazyOverrideFooter
          navItems={[
            {
              route: `${LOYALTY_SHARE_ROUTE}?passId=${passId}`,
              icon: SHARE,
              message: 'Share',
            },
            {
              route: `${LOYALTY_SHARE_IOS_ROUTE}?passId=${passId}`,
              icon: APPLE,
              message: 'iOS',
            },
            {
              route: `${LOYALTY_SHARE_ANDROID_ROUTE}?passId=${passId}`,
              icon: ANDROID,
              message: 'Android',
            },
            { route: `${LOYALTY_ROUTE}?passId=${passId}`, icon: REWARD, message: 'Loyalty' },
          ]}
          currentItem="Share"
          />
      </Page>
  );
};

SuspendedSharePage.propTypes = {
  passId: string,
  title: string,
};

export default SuspendedSharePage;
